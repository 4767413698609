import { useState, useEffect, memo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { MenuItem, Stack, TextField, Unstable_Grid2 as Grid, Button, Typography } from '@mui/material'
import { UnfoldMore } from '@mui/icons-material'
import { ALL_GARMENTS, BREEK, COAT_PANT_PANT, COAT_VEST_PANT_PANT, requireGarmentType, SWACKET } from '@trinity/utils'
import { StyleflowToggleSwitch } from './StyleflowToggleSwitch'

export function StyleflowCollectionGarments({ fabricImages, garmentType }: StyleflowCollectionGarmentsProps) {
  const [fabrics, setFabrics] = useState<TrinityAPI.FabricImageType[]>([])

  return (
    <Stack spacing={4}>
      <Stack direction='row' justifyContent='space-between'>
        <Stack direction='row' spacing={4}>
          <GarmentTypeSelect garmentType={garmentType} />
          <MemoModelSelect fabricImages={fabricImages} setFabrics={setFabrics} />
        </Stack>
        <StyleflowToggleSwitch currentView='garments' />
      </Stack>
      <Grid container spacing={8}>
        {fabrics.map((fabric, index) => (
          <GarmentImage key={fabric.id} fabric={fabric} position={index + 1} />
        ))}
      </Grid>
    </Stack>
  )
}

function GarmentTypeSelect({ garmentType }: GarmentTypeSelectProps) {
  const [_params, setParams] = useSearchParams()
  const filteredGarmentTypes = ALL_GARMENTS.filter(type => !IGNORED_GARMENT_TYPES.includes(type.bitmask))

  return (
    <TextField
      select
      size='small'
      name='garmentType'
      label='Garment Type'
      SelectProps={{ IconComponent: UnfoldMore }}
      value={garmentType?.bitmask}
      sx={{ width: 200 }}
      onChange={e => {
        const garmentType = requireGarmentType(Number(e.target.value))
        setParams({ garmentType: garmentType.bitmask.toString() })
      }}
    >
      {filteredGarmentTypes.map(type => (
        <MenuItem key={type.bitmask} value={type.bitmask}>
          {type.alternateName ?? type.formalName}
        </MenuItem>
      ))}
    </TextField>
  )
}

function ModelSelect({ fabricImages, setFabrics }: ModelSelectProps) {
  const models = Object.keys(fabricImages)
  const defaultModel = models[0]
  const [model, setModel] = useState(defaultModel)

  useEffect(() => {
    if (model && models.includes(model)) return

    setModel(defaultModel)
  }, [defaultModel, model, models])

  useEffect(() => {
    if (model) {
      const fabrics = fabricImages[model]
      setFabrics(fabrics ?? [])
    }
  }, [fabricImages, model, setFabrics])

  if (!model || !models.includes(model)) return null

  return (
    <TextField
      select
      size='small'
      name='model'
      label='Model'
      value={model}
      sx={{ width: 200 }}
      onChange={e => {
        const model = e.target.value
        const fabrics = fabricImages[model]
        setFabrics(fabrics ?? [])
        setModel(model)
      }}
    >
      {models.map(model => (
        <MenuItem key={model} value={model}>
          {model}
        </MenuItem>
      ))}
    </TextField>
  )
}

function GarmentImage({ fabric, position }: GarmentImageProps) {
  const [showButtons, setShowButtons] = useState(false)
  const navigate = useNavigate()

  return (
    <Grid>
      <Stack
        alignItems='center'
        onMouseEnter={() => setShowButtons(true)}
        onMouseLeave={() => setShowButtons(false)}
        // boxShadow={theme => theme.elevation.card}
        // p={1}
        position='relative'
      >
        <Stack spacing={2} alignItems='center' position='absolute' top='35%' display={showButtons ? '' : 'none'}>
          <Button
            size='small'
            color='neutral'
            onClick={() => navigate(`/styleflow/fabrics/${fabric.id}`)}
            sx={{ borderRadius: 50 }}
          >
            Customize Garment
          </Button>
          <Button size='small' onClick={() => alert('Added to rack')} sx={{ borderRadius: 50, width: 'fit-content' }}>
            Add To Rack
          </Button>
        </Stack>
        <img
          src={fabric.image}
          alt={fabric.trinityFabricNumber}
          loading='lazy'
          height={isSuit(fabric) ? 325 : 400}
          width={isSuit(fabric) ? 325 : 250}
        />
        <Typography variant='h6'>{`${fabric.trinityFabricNumber} (${position})`}</Typography>
        <Typography variant='smallBody2'>{fabric.fabricDescription}</Typography>
      </Stack>
    </Grid>
  )
}

//* HELPERS
const isSuit = (fabric: TrinityAPI.FabricImageType) => fabric.garmentType.formalName === 'Suit'

const IGNORED_GARMENT_TYPES: number[] = [
  COAT_PANT_PANT.bitmask,
  COAT_VEST_PANT_PANT.bitmask,
  BREEK.bitmask,
  SWACKET.bitmask,
]

const MemoModelSelect = memo(ModelSelect)

//* TYPES
interface StyleflowCollectionGarmentsProps {
  fabricImages: Partial<Record<string, TrinityAPI.FabricImageType[]>>
  garmentType: (typeof ALL_GARMENTS)[number]
}

interface GarmentTypeSelectProps {
  garmentType: Pick<StyleflowCollectionGarmentsProps, 'garmentType'>['garmentType']
}

interface ModelSelectProps {
  fabricImages: Partial<Record<string, TrinityAPI.FabricImageType[]>>
  setFabrics: (fabrics: TrinityAPI.FabricImageType[]) => void
}

interface GarmentImageProps {
  fabric: TrinityAPI.FabricImageType
  position: number
}
