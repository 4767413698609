import { Outlet, useNavigate } from 'react-router-dom'
import { Chip, Divider, Stack, Typography } from '@mui/material'
import { BackButton } from '../../components'

interface StyleflowCollectionProps {
  collection: TrinityAPI.CollectionType
}

export function StyleflowCollection({ collection }: StyleflowCollectionProps) {
  const navigate = useNavigate()

  return (
    <Stack spacing={4}>
      <BackButton to='/styleflow/collections' />
      <Stack direction='row' justifyContent='space-between'>
        <Stack direction='row' spacing={4}>
          <img src={collection.image} alt={collection.name} height={100} width={100} />
          <div>
            <Typography gutterBottom variant='h1'>
              {collection.brand ?? collection.title}
            </Typography>
            <Typography variant='body1'>{collection.title}</Typography>
          </div>
        </Stack>
        <Chip label='Rack' sx={{ width: 'fit-content' }} onClick={() => navigate('/cart')} />
      </Stack>
      <Divider />
      <Outlet />
    </Stack>
  )
}
