import { useNavigate } from 'react-router-dom'
import { ToggleButton } from '@mui/material'
import { ToggleButtonGroup } from '../../components'

interface StyleflowToggleSwitchProps {
  currentView: typeof SWATCHES | typeof GARMENTS
}

export function StyleflowToggleSwitch({ currentView }: StyleflowToggleSwitchProps) {
  const navigate = useNavigate()

  return (
    <ToggleButtonGroup
      value={currentView}
      onChange={(_e, v) => {
        if (v) {
          navigate(`../${v}`)
        }
      }}
      sx={{ width: 'fit-content', height: 40, alignSelf: 'flex-end' }}
    >
      <ToggleButton value={SWATCHES}>Swatches</ToggleButton>
      <ToggleButton value={GARMENTS}>Garments</ToggleButton>
    </ToggleButtonGroup>
  )
}

const SWATCHES = 'swatches'
const GARMENTS = 'garments'
