import { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import Highlighter from 'react-highlight-words'
import {
  Chip,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material'
import { FilterList } from '@mui/icons-material'
import { handleCollectionFolderQrScan } from '@trinity/utils'
import { CollectionCard, Pagination, Search } from '../../components'
import { useFilters } from '../../hooks'

interface StyleflowCollectionsProps {
  filters: TrinityAPI.FiltersType
  collections: TrinityAPI.CollectionType[]
  totalCollections: number
}

export function StyleflowCollections({ filters, collections, totalCollections }: StyleflowCollectionsProps) {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const { Filters, FilterChips } = useFilters({
    open: drawerOpen,
    close: () => setDrawerOpen(false),
    filters: filters,
  })
  return (
    <Stack direction='row' spacing={4}>
      <Filters />
      <Stack spacing={4} width={1}>
        <Header openDrawer={() => setDrawerOpen(true)} />
        <Divider />
        <FilterChips />
        {collections.length < 1 ? (
          <Typography textAlign='center' variant='h4'>
            No collections found
          </Typography>
        ) : (
          <Grid
            container
            alignItems='center'
            justifyContent={{ mobile: 'center', tablet: 'space-evenly' }}
            spacing={2}
            mt={4}
          >
            {collections.map(collection => (
              <Grid key={collection.id}>
                <CollectionCard collection={collection} />
              </Grid>
            ))}
          </Grid>
        )}
        <Pagination total={totalCollections} pageSizeOptions={[24, 48, 96]} sx={{ pt: 5 }} />
      </Stack>
    </Stack>
  )
}

function Header({ openDrawer }: { openDrawer: () => void }) {
  const navigate = useNavigate()

  return (
    <Stack spacing={4}>
      <Stack
        direction={{ mobile: 'row', laptop: 'row' }}
        spacing={4}
        justifyContent='space-between'
        alignItems='center'
        pt={{ mobile: 6, laptop: 0 }}
      >
        <Typography variant='h1'>Styleflow</Typography>
        <Chip label='Rack' onClick={() => navigate('/styleflow/cart')} />
      </Stack>
      <Stack direction='row' spacing={1} width={{ mobile: 1, laptop: 350 }}>
        <Search<TrinityAPI.CollectionType>
          withQrScanner
          qrFunction={handleCollectionFolderQrScan}
          name='search'
          placeholder='Search collections...'
        >
          {({ suggestion, searchTerm }) => <CollectionSuggestion suggestion={suggestion} searchTerm={searchTerm} />}
        </Search>
        <IconButton
          color='primary'
          onClick={openDrawer}
          sx={{ border: 'primary', borderRadius: 1, px: 2.5, display: { laptop: 'none' } }}
        >
          <FilterList />
        </IconButton>
      </Stack>
    </Stack>
  )
}

function CollectionSuggestion({
  suggestion,
  searchTerm,
}: {
  suggestion: TrinityAPI.CollectionType
  searchTerm: string
}) {
  return (
    <ListItem disablePadding>
      <ListItemButton component={Link} to={String(suggestion.id)}>
        <ListItemText primaryTypographyProps={{ variant: 'body3' }}>
          <Highlighter searchWords={[searchTerm]} textToHighlight={suggestion.name} highlightTag='strong' />
        </ListItemText>
      </ListItemButton>
    </ListItem>
  )
}
